


























































































import { UserState } from "@/common/store/user/types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/libs/axios/axios";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";

@Component
export default class FooterMenu extends Vue {
  @State("user") user!: UserState;
  openSubstitutionCount = 0;

  checkPermission(permission: string) {
    return this.user.permissions.includes(permission);
  }

  async mounted() {
    if (
      this.user.currentTenant &&
      this.user.currentTenant.id &&
      this.checkPermission("CAN_SUBSTITUTE")
    ) {
      const openSubstitution: void | AxiosResponse = await axios
        .get("substitutions/open/count", {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (openSubstitution && openSubstitution.data) {
        this.openSubstitutionCount = openSubstitution.data.count;
      }
    }
  }
}
