







import Vue from "vue";
import { Component } from "vue-property-decorator";
import FooterMenu from "@/common/components/Menu/FooterMenu.vue";

@Component({
  components: {
    FooterMenu
  }
})
export default class DashboardAbstract extends Vue {
  get hideFooter(): boolean {
    return this.$route.meta && this.$route.meta.hideFooter
      ? this.$route.meta.hideFooter
      : false;
  }
}
